import { useMemo, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcFormatRawDataAsList,
  AcFormatInternalURI,
  AcFormatRole,
  AcHasErrors,
} from '@utils';

// Imports => Hooks
import { useOverviewActions, usePermissions } from '@hooks';

// Imports => Components
const AcTable = loadable(() => import('@components/ac-table/ac-table'));

// Imports => Atoms
import { AcColumn, AcRow } from '@atoms/ac-grid';
const AcCard = loadable(() => import('@atoms/ac-card/ac-card'));
const AcSearchInput = loadable(() =>
  import('@atoms/ac-search-input/ac-search-input')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon'));
const AcSelectBox = loadable(() =>
  import('@atoms/ac-select-box/ac-select-box.web')
);

const AcUserTable = ({
  type,
  store: { users, ui, auth, profile, companies },
}) => {
  const navigate = useNavigate();
  const { current_users, current_order_by, is_loading, roles_query } = users;
  const { handlePagination, handleSort, handleSearch } =
    useOverviewActions(users);

  const { can, cannot } = usePermissions();

  const [rolesVisible, setRolesVisible] = useState(false);

  const $searchInput = useRef(null);

  useEffect(() => {
    if (type) {
      let params = {};

      if (type === 'clients') {
        params.companyRoles = 1;
        companies.list({ options: true });
      } else if (type === 'iqip') {
        params.iqipRoles = 1;
      }

      users.get_roles(params);
    }
  }, [type]);

  const handleDelete = (object) => {
    const { 'users.name': name, 'users.id': id } = object;
    ui.confirm({
      instance: users,
      title: TITLES.DELETE_USER,
      content: `<p>You are about to permanently delete user <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new Promise((resolve, reject) => {
            users
              .delete({ id, name })
              .then(async (resolve) => {
                await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
                resolve();
              })
              .catch((error) => {
                const errors = AcHasErrors(error);
                if (!AcHasErrors(error)) return;

                ui.display_linked_to({
                  errors,
                  title: TITLES.FAILED_TO_DELETE_USER,
                });

                reject(error);
              });
          });
        },
      },
    });
  };

  const handleImpersonate = async (obj) => {
    const list = new AcFormatRawDataAsList(
      obj,
      { ID: 'users.id', NAME: 'users.name' },
      null,
      true
    );
    const { id, name } = list;
    await auth.impersonate({ id, name });
    await profile.who_am_i().then(() => {
      if (navigate) navigate('/', { replace: true });
    });
  };

  const handleViewUserDetails = async (obj) => {
    const list = new AcFormatRawDataAsList(
      obj,
      { ID: 'users.id', NAME: 'users.name' },
      null,
      true
    );

    const { id } = list;
    const route = AcFormatInternalURI(
      { id, entity: KEYS.USER },
      'View user details'
    );

    if (navigate && route) navigate(route);
  };

  const renderSearchInput = useMemo(() => {
    return (
      <AcSearchInput
        placeholder={'Find users (eg. by name, email address)'}
        callback={handleSearch}
        ref={$searchInput}
      />
    );
  }, [is_loading, handleSearch, type]);

  const handleRoleSelection = (event, name, value, type) => {
    if (value === null) {
      users.setRoleQuery([]);
    } else {
      let new_query = [];
      if (users.current_roles_query.indexOf(value) > -1) {
        new_query = users.current_roles_query.filter((n) => n !== value) || [];
        users.setRoleQuery(new_query);
      } else {
        new_query = [...users.current_roles_query, value];
        users.setRoleQuery(new_query);
      }
    }

    users.list();
  };

  const handleCompanySelection = (event, name, value, type) => {
    if (value === null) {
      users.setCompanyQuery([]);
    } else {
      let new_query = [];
      if (users.current_company_query.indexOf(value) > -1) {
        new_query =
          users.current_company_query.filter((n) => n !== value) || [];
        users.setCompanyQuery(new_query);
      } else {
        new_query = [...users.current_company_query, value];
        users.setCompanyQuery(new_query);
      }
    }

    users.list();
  };

  const renderResetFiltersButton = useMemo(() => {
    let hasActiveFilters =
      users.query?.length ||
      users.current_company_query?.length ||
      users.current_roles_query?.length;

    if (!hasActiveFilters) return null;

    const options = {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TRANSPARENT,
      title: 'Clear selected filters',
      disabled: is_loading || !hasActiveFilters,
      callback: (event) => {
        if (event?.preventDefault) event.preventDefault();
        if (event?.persist) event.persist();

        users.setCompanyQuery([]);
        users.setRoleQuery([]);
        users.setQuery(null);

        if ($searchInput?.current?.resetValue) {
          $searchInput?.current?.resetValue();
        }

        users.list();
      },
    };

    return (
      <AcButton {...options}>
        <AcIcon icon={ICONS.CLOSE} />
        <span>Clear filters</span>
      </AcButton>
    );
  }, [
    users,
    is_loading,
    users.current_company_query,
    users.current_roles_query,
  ]);

  const renderRolesDropdown = useMemo(() => {
    const options = users.current_roles.map(({ id, name }) => ({
      value: name,
      name: AcFormatRole(name),
    }));

    options.unshift({ value: null, name: 'All roles' });

    const selectBoxOptions = {
      type: TYPES.TEXT,
      label: 'Roles',
      name: 'roles',
      placeholder: 'Filter by role',
      value: users.current_roles_query || null,
      callback: handleRoleSelection,
      maxOptions: 6,
      options,
    };

    return (
      <AcSelectBox
        {...selectBoxOptions}
        title={'Filter result by role'}
        multiple
        is_filter
      />
    );
  }, [
    is_loading,
    rolesVisible,
    users.current_roles,
    users.current_roles_query,
  ]);

  const renderCompanyDropdown = useMemo(() => {
    if (type !== 'clients') return null;
    if (
      cannot(PERMISSIONS.USER.READ_ALL) ||
      cannot(PERMISSIONS.COMPANY.READ_ALL)
    )
      return null;
    if (!companies?.current_companies_list) return null;

    const options = companies?.current_companies_list?.map(({ id, name }) => ({
      value: id,
      name: name,
    }));

    options.unshift({ value: null, name: 'All companies' });

    const selectBoxOptions = {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company',
      placeholder: 'Filter by company',
      value: users.current_company_query || null,
      callback: handleCompanySelection,
      maxOptions: 6,
      options,
    };

    return (
      <AcSelectBox
        {...selectBoxOptions}
        title={'Filter result by company'}
        multiple
        is_filter
      />
    );
  }, [type, users.current_company_query, companies?.current_companies_list]);

  const renderTable = useMemo(() => {
    if (is_loading) return <AcLoader loading={true} />;
    if (!current_users)
      return <AcEmptyBlock message={'No results found to display.'} />;

    const { data, headers, meta } = current_users;

    if (!data || data.length === 0)
      return <AcEmptyBlock message={'No results found to display.'} />;

    let actions = [];

    if (can(PERMISSIONS.USER.READ)) {
      actions.push([
        {
          label: 'View user details',
          icon: ICONS.ACCOUNT_OUTLINE,
          type: TYPES.LINK,
          field: KEYS.OBJECT,
          callback: handleViewUserDetails,
        },
      ]);
    }

    if (can(PERMISSIONS.USER.IMPERSONATE)) {
      actions.push([
        {
          label: 'Impersonate',
          icon: ICONS.NINJA,
          field: KEYS.OBJECT,
          callback: handleImpersonate,
        },
      ]);
    }

    if (
      can(PERMISSIONS.USER.MANAGE_ALL) ||
      (type === 'clients' && can(PERMISSIONS.USER.MANAGE))
    ) {
      actions.push([
        {
          label: 'Delete',
          field: KEYS.OBJECT,
          icon: ICONS.TRASH_CAN_OUTLINE,
          type: TYPES.DELETE,
          callback: handleDelete,
        },
      ]);
    }

    if (!actions?.length) {
      actions = null;
    }

    const options = {
      columns: headers,
      rows: data,
      pagination: meta,
      sortby: current_order_by,
      onSort: handleSort,
      onPaginate: handlePagination,
      actions,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [
    current_users,
    current_order_by,
    handleViewUserDetails,
    handleDelete,
    handleImpersonate,
    is_loading,
    type,
  ]);

  return (
    <AcRow>
      <AcRow className={'h-margin-bottom-20'}>
        <AcColumn xs={12} sm={12} md={7} lg={5}>
          {renderSearchInput}
        </AcColumn>
      </AcRow>

      <AcRow className={'h-margin-bottom-24'}>
        <AcColumn xs={12} sm={12} md={9}>
          <div className={'ac-filter-wrap'}>
            {renderCompanyDropdown}
            {renderRolesDropdown}
            {renderResetFiltersButton}
          </div>
        </AcColumn>
      </AcRow>

      <AcRow>
        <AcColumn>
          <AcCard dense flat>
            {renderTable}
          </AcCard>
        </AcColumn>
      </AcRow>
    </AcRow>
  );
};

export default withStore(observer(AcUserTable));
