import { useEffect, useState, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { DATETIME_FORMATS, KEYS, THEMES, TITLES, TYPES } from '@constants';

import { useFormActions, useTermsAndConditions } from '@hooks';
import { AcFormatDate, AcSetDocumentTitle } from '@utils';

import { withStore } from '@stores';

import AcButton from '@atoms/ac-button/ac-button';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content';
import AcDivider from '@atoms/ac-divider/ac-divider';
import { AcColumn, AcRow } from '@atoms/ac-grid';

const _CLASSES = {
  MAIN: 'ac-modal-terms-and-conditions-modal',
};

const AcTermsAndConditionsModal = ({ store }) => {
  const $modal = useRef(null);
  const { is_loading } = store.auth;

  const { content } = useTermsAndConditions(store);

  let raw_fields = {
    accept_terms: false,
  };
  let raw_errors = {
    accept_terms: undefined,
  };

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const { handleInputChange, handleInputValidation, hasErrors } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  useEffect(() => {
    AcSetDocumentTitle(TITLES.TERMS_AND_CONDITIONS);
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    let data = {
      accept_terms: AcFormatDate(
        new Date(),
        null,
        DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_NO_COMMA
      ),
    };

    store.profile
      .update(data)
      .then((x) => {
        store.ui.reset(KEYS.MODAL);
        store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: !fields.accept_terms || is_loading,
      loading: is_loading,
      title: 'Submit',
      callback: () => onSubmitHandler,
    };
  }, [hasErrors, is_loading, fields, fields.accept_terms]);

  const getAgreeCheckboxOptions = useMemo(() => {
    return {
      type: TYPES.CHECKBOX,
      name: 'accept_terms',
      value: 'agreed',
      callback: (event, name, value, type, checked) => {
        handleInputChange(event, name, value, type, checked);
      },
      required: true,
      validation: handleInputValidation,
      checked: fields.accept_terms,
    };
  }, [fields, fields.accept_terms]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  return (
    <div className={getMainClassNames} ref={$modal}>
      <form method={'post'} autoComplete={'off'} onSubmit={onSubmitHandler}>
        <AcRow>
          <AcColumn>{content}</AcColumn>
        </AcRow>

        <AcRow className={'h-margin-y-25'}>
          <AcColumn className={'h-text--align-center'}>
            <AcDivider theme={THEMES.LIGHT} />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcCheckbox {...getAgreeCheckboxOptions}>
              <span>I agree to the terms and conditions</span>
            </AcCheckbox>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-y-15'}>
          <AcColumn>
            <AcButton {...getSubmitButtonOptions}>
              <span>Submit</span>
            </AcButton>
          </AcColumn>
        </AcRow>
      </form>
    </div>
  );
};

export default withStore(observer(AcTermsAndConditionsModal));
